@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");

body {
  background-color: #fafcfe !important;
}

.content {
  width: 100%;
  display: flex;
}

.display-none {
  display: none;
}

.whiteNoWrap {
  white-space: nowrap;
}

.bg-#F2FAF9 {
  background-color: #f2faf9 !important;
}

.w-900 {
  width: 900px !important;
  margin: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000000s ease-in-out 0s;
}

div.google-visualization-tooltip {
  background: #001620;
  border-radius: 10px;
  width: 150px;
}

div.google-visualization-tooltip > ul > li > span {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff !important;
}

.modal-header-custom {
  padding-bottom: 0 !important;
  padding-right: 20px !important;
  padding-top: 15px !important;
  margin-bottom: 30px !important;
}

.modal-content-custom {
  background: #fafcfe !important;
  border-radius: 30px !important;
  padding-bottom: 50px !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-F2FAF9 {
  background-color: #f2faf9 !important;
}

.complete-btn {
  width: 72px;
  height: 17px;
  background: #25d4a9;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center !important;
  padding-top: 2px;
}

.cancel-btn {
  width: 72px;
  height: 17px;
  background: red;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center !important;
  padding-top: 2px;
}

.button-play {
  width: 31px;
  height: 31px;
  background-color: #e9fbf6;
  background-image: url("../images/IconPlay.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
}

.button-push {
  width: 31px;
  height: 31px;
  background-color: #fff6e5;
  background-image: url("../images/IconPush.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
}

.button-stop {
  width: 31px;
  height: 31px;
  background-color: #ffe9e9;
  background-image: url("../images/IconStop.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
  margin-right: 3px;
}

.button-complete {
  width: 31px;
  height: 31px;
  background-color: #25d4a9;
  background-image: url("../images/IconComplete.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
  margin-right: 3px;
}

.button-copy {
  width: 31px;
  height: 31px;
  background-color: #f7e5f8;
  background-image: url("../images/IconCopy.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
  margin-right: 3px;
}

.button-edit {
  width: 31px;
  height: 31px;
  background-color: #d3e4e0;
  background-image: url("../images/editicon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
  margin-right: 3px;
}

.button-delete {
  width: 31px;
  height: 31px;
  background-color: #e8d3d3;
  background-image: url("../images/Delete.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 7px;
  margin-right: 3px;
}

.button-delete:hover {
  background-color: #ff2525 !important;
  background-image: url("../images/IconDeleteWhite.svg") !important;
}

.button-edit:hover {
  background-color: #25d4a9 !important;
  background-image: url("../images/IconEditWhite.svg") !important;
}

.button-copy:hover {
  background-color: #ae00b9 !important;
  background-image: url("../images/IconCopyWhite.svg") !important;
}

.button-execute {
  width: 130px;
  height: 32px;
  background: rgba(37, 212, 169, 0.05);
  border: 1px solid rgba(37, 212, 169, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #25d4a9;
}

.button-execute:hover {
  background: #25d4a9;
  color: #ffffff;
}

.button-go {
  width: 50px;
  height: 35px;
  background: rgba(174, 0, 185, 0.1);
  border-radius: 7px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ae00b9;
  text-decoration: none;
  text-align: center;
  padding-top: 10px;
}

.button-go:hover {
  background: #ae00b9;
  color: white;
}

.button-go:focus-visible {
  outline: none;
}

.button-play:hover {
  background-color: #25d4a9;
  background-image: url("../images/IconPlayWhite.svg");
}

.button-push:hover {
  background-color: #ffa800;
  background-image: url("../images/IconPushWhite.svg");
}

.button-stop:hover {
  background-color: #ff2525;
  background-image: url("../images/IconStopWhite.svg");
}

.custom-btn-close {
  width: 30px;
  height: 30px;
  background: rgba(255, 27, 40, 0.1);
  border: 1px solid #fbcfd3;
  box-sizing: border-box;
  border-radius: 30px;
  background-image: url("../images/IconCross.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  bottom: 25px;
}

.w100 {
  width: 100% !important;
}

.w100 > input {
  width: 100% !important;
}

/*Login Section*/
.login-section {
  width: 500px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
  margin: auto;
  position: relative;
  margin-top: 10vh;
}

.login-section-title {
  padding: 23px 15px 10px 20px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #130f40;
  border-bottom: 1px solid #dddfe4;
}

.login-section-body {
  width: 500px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 25px;
}

.login-section-input > label {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #130f40;
  margin-bottom: 7px;
}

.login-section-input > input {
  width: 100%;
  height: 50px;
  background: linear-gradient(
    180deg,
    rgba(165, 246, 255, 0.02) 0%,
    rgba(24, 159, 255, 0.02) 100%
  );
  border: 1px solid rgba(19, 15, 64, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 12px;
}

.login-section-forgot > input {
  width: 100%;
}

.login-section-forgot > label {
  float: left;
}

.forgot-section {
  height: auto !important;
  padding-bottom: 50px;
}

.forgot-section-body {
  height: auto !important;
}

.login-section-forgot {
  text-align: right;
}

.login-section-forgot > a {
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #130f40;
  mix-blend-mode: normal;
  opacity: 0.7;
}

.login-section-sign {
  width: 100%;
  height: 50px;
  background: #ae00b9;
  mix-blend-mode: normal;
  opacity: 0.7;
  border-radius: 15px;
  border: none;
  margin-bottom: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.login-section-google {
  width: 100%;
  height: 50px;
  background: #e94235;
  border-radius: 12px;
  border: none;
  margin-bottom: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.login-section-fb {
  width: 100%;
  height: 50px;
  background: #1877f2;
  border-radius: 12px;
  border: none;
  margin-bottom: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.login-section-github {
  width: 100%;
  height: 50px;
  background: #040d21;
  mix-blend-mode: normal;
  border-radius: 12px;
  border: none;
  margin-bottom: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.login-section-footer {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #b6b4c3;
  mix-blend-mode: normal;
}

.login-section-footer > a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #130f40;
  text-decoration: none;
}

.sidebar-menu {
  width: 300px;
  background: #ffffff;
  box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 2;
  overflow-x: scroll;
}

.sidebar-menu::-webkit-scrollbar {
  display: none;
}

.sidebar-menu {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar-menu ::-webkit-scrollbar {
  display: none;
}
.sidebar-menu-top {
  display: flex;
  padding: 15px 8px 0px 20px;
  width: 100%;
  height: 40px;
}

.sidebar-menu-logo {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #1c2649;
}

.sidebar-menu-logo > img {
  width: 165px;
}

.sidebar-menu-more-icon {
  width: 50%;
}

.sidebar-menu-more-icon > img {
  float: right;
}

.menu-active > ul {
  display: block;
}

.sidebar-menu-category {
  width: 100%;
}

.sidebar-menu-category > select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 87%;
  height: 50px;
  border: 1px solid #d0cfd9;
  border-radius: 12px;
  margin: 20px 20px;
  padding: 10px;
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 5px),
    calc(100% - 15px) calc(1em + 5px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  overflow: hidden;
}

.sidebar-menu-category > select:focus {
  outline: none;
}

.sidebar-menu-category > select:hover {
  /*background-color: #fff !important;*/
  /*box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;*/
  /*border-color: rgba(223, 225, 229, 0) !important;*/
  border: 1px solid black;
  overflow: hidden;
}

.sidebar-menu-list {
  width: 260px;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0px 8px 0px 20px;
  padding-bottom: 75px;
}

.sidebar-menu-list-ul {
  list-style: none;
  padding-left: 0;
}

.sidebar-menu-list-ul-li {
  padding-bottom: 25px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1c2649;
  padding-top: 5px;
  height: 48px;
  margin-bottom: 2px;
}

.sidebar-menu-list-ul-li > a {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1c2649;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-menu-list-ul-li:hover {
  background: rgba(174, 0, 185, 0.05);
  border-radius: 12px;
}

.sidebar-menu-arrow {
  float: right;
  margin-top: 8px;
  margin-right: 0px !important;
}

.sidebar-menu-active {
  background: rgba(174, 0, 185, 0.05);
  border-radius: 12px;
}

.sidebar-menu-active > a > span {
  color: #c549cd !important;
}

.sidebar-menu-list-sub-ul {
  list-style: none;
  padding-left: 0;
}

.sidebar-menu-list-sub-ul > li {
  padding-left: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: black;
  border-radius: 6px;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.sidebar-menu-divider {
  display: flex;
  align-items: baseline;
  padding-left: 10px;
  margin-bottom: 10px;
}
.sidebar-menu-divider span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #1c2649;
}
.sidebar-menu-divider div {
  height: 1px;
  background: #d2d4db;
  flex: 1;
  margin-left: 5px;
}

.dashboard-icon {
  width: 40px;
  height: 40px;
  background-image: url("../images/IconDashboard.svg");
  background-repeat: no-repeat;
}

.dashboard-icon-active {
  width: 40px;
  height: 40px;
  background-image: url("../images/iconDashboardC_1.svg");
  background-repeat: no-repeat;
}
.project-icon {
  width: 40px;
  height: 40px;
  background-image: url("../images/IconProject.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.project-icon-active {
  width: 40px;
  height: 40px;
  background-image: url("../images/IconProjectC.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.campaign-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconCampaign.svg");
  background-repeat: no-repeat;
}

.campaign-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconCampaignC.svg");
  background-repeat: no-repeat;
}

.domain-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconDomain.svg");
  background-repeat: no-repeat;
}

.domain-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconDomainC.svg");
  background-repeat: no-repeat;
}

.hosting-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconHosting.svg");
  background-repeat: no-repeat;
}

.hosting-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconHostingColor.svg");
  background-repeat: no-repeat;
}

.server-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconServer.svg");
  background-repeat: no-repeat;
}

.server-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconServerC.svg");
  background-repeat: no-repeat;
}

.cart-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconCart.svg");
  background-repeat: no-repeat;
}

.cart-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconCartC.svg");
  background-repeat: no-repeat;
}

.invoice-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconInvoice.svg");
  background-repeat: no-repeat;
}

.invoice-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconInvoiceC.svg");
  background-repeat: no-repeat;
}

.finance-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconInvoiceFin.svg");
  background-repeat: no-repeat;
}

.finance-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconInvoiceFinC.svg");
  background-repeat: no-repeat;
}
.setting-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconSettingSoft.svg");
  background-repeat: no-repeat;
}

.setting-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconSettingSoftC.svg");
  background-repeat: no-repeat;
}

.user-roll-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconUserRoll.svg");
  background-repeat: no-repeat;
}

.user-roll-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconUserRollC.svg");
  background-repeat: no-repeat;
}

.reports-icon {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconReport.svg");
  background-repeat: no-repeat;
}

.reports-icon-active {
  border: none;
  width: 40px;
  height: 40px;
  background-image: url("../images/IconReportC.svg");
  background-repeat: no-repeat;
}

.sidebar-footer {
  padding: 15px 8px 5px 20px;
  position: fixed;
  bottom: 16px;
  width: 290px;
  display: flex;
  background: white;
}

.sidebar-footer > button {
  border: none;
  background: none;
}

.sidebar-footer-menu {
  width: 85%;
  height: 90px;
  background: #dbe6f0;
  position: absolute;
  bottom: 35px;
  padding: 20px 10px 5px 0;
  border-radius: 10px;
}

.sidebar-footer-menu > ul {
  padding-left: 15px;
}

.sidebar-footer-menu > ul > li {
  list-style: none;
}

.sidebar-footer-menu > ul > li > a {
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #1c2649;
}

.sidebar-footer-menu > ul > li > a:hover {
  color: #009686;
}

.sideFooterLogout {
  width: 40px;
  height: 40px;
  background-image: url("../images/IconLogout.svg") !important;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
  float: right;
  position: relative;
  bottom: 3px;
}

.sideFooterLogout:hover {
  background-image: url("../images/IconLogoutColor.svg") !important;
}

.sideFooterSetting {
  width: 40px;
  height: 40px;
  background-image: url("../images/IconSetting.svg") !important;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
  float: right;
  position: relative;
  bottom: 3px;
}

.sideFooterSetting:hover {
  background-image: url("../images/IconSettingColor.svg") !important;
}

.sideFooter-user {
  display: flex;
  position: relative;
  top: 10px;
  width: 190px;
}

.sideFooter-user > p {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  position: relative;
  top: 5px;
}

.sideFooter-user-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background: #000;
  margin-right: 10px;
  margin-left: -6px;
}

.main-stricture {
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 300px;
  overflow: hidden;
}

.main-stricture-header {
  width: 100%;
  height: 75px;
  padding: 20px 8px 0 31px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #130f40;
}

.main-stricture-header > button {
  display: none;
}

.main-panel-title {
  width: 30%;
  height: 40px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #130f40;
  margin: 20px;
  display: flex;
}

.sidebar-panel {
  width: 320px;
  height: 850px;
  background: #ffffff;
  box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
  border-radius: 25px;
  margin-left: 30px;
}

.sidebar-panel-search {
  width: 30%;
  height: 40px;
  border: 1px solid #d0cfd9;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px 0 20px 20px;
  display: flex;
}

.sidebar-panel-search-date {
  width: 250px;
  height: 40px;
  border: 1px solid #d0cfd9;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px 0 20px 20px;
  display: flex;
}

.sidebar-panel-search:hover {
  background-color: #fff !important;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;
  border-color: rgba(223, 225, 229, 0) !important;
}

.sidebar-panel-search-date:hover {
  background-color: #fff !important;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;
  border-color: rgba(223, 225, 229, 0) !important;
}

.sidebar-panel-search > input {
  border: none;
  background: none;
  width: 92%;
  outline: none;
  padding-left: 10px;
}

.sidebar-panel-search-date > input {
  border: none;
  background: none !important;
  outline: none;
  padding: 0px 10px 0 10px;
  width: 88%;
}

.sidebar-panel-search-date > input:focus {
  box-shadow: none !important;
}

.sidebar-panel-search > img {
  width: 14.67px;
  height: 15px;
  margin-top: 11px;
}

.sidebar-panel-search-date > img {
  width: 14.67px;
  height: 15px;
  margin-top: 11px;
}

.sidebar-panel-list {
  width: 280px;
  margin: 20px;
}

.sidebar-panel-list > ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.sidebar-panel-list > ul > li {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 14px;
  color: #130f40;
  margin-bottom: 10px;
}

.sidebar-panel-list > ul > li > img {
  float: right;
}

.sidebar-panel-list > ul > li:hover {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 14px;
  color: #130f40;
  margin-bottom: 10px;
  background: #fbf2fb;
  border-radius: 12px;
}

.sidebar-panel-list-active {
  background: #fbf2fb;
  border-radius: 12px;
}

.main-panel {
  width: 100%;
  min-height: 850px;
  background: #ffffff;
  box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
  border-radius: 25px;
  margin-left: 30px;
  padding-bottom: 50px;
}

.main-panel-header {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  position: relative;
  margin-bottom: 30px;
}

.main-panel-header-search {
  width: 80%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  position: relative;
  margin-bottom: 30px;
  flex: 1 1 auto;
}

.main-panel-header button {
  height: 40px;
  background: #ae00b9;
  border-radius: 10px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  float: right;
  padding: 0 20px 0 20px;
  margin: 20px;
}

.main-panel-header-btn {
  width: 20%;
  margin: 20px;
  position: absolute;
  right: 0;
}

.accordion-btn {
  border: none;
  background: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.accordion-header {
  height: 54px;
}

.accordion-body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.accordion-tr {
  display: none;
}

.drop-menu {
  position: absolute;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
  padding: 15px 35px 3px 22px;
  top: -20px;
  display: none;
  right: 0px;
  z-index: 999;
  width: 209px;
  border-radius: 10px;
}

.drop-menu > ul {
  padding-left: 0;
}

.drop-menu > ul > li {
  list-style: none;
}

.drop-menu > ul > li > a {
  text-decoration: none;
  color: black;
  line-height: 30px;
}

.drop-menu > ul > li > a:hover {
  color: #009686;
}

.main-panel-header-btn > a {
  height: 40px;
  background: #ae00b9;
  border-radius: 10px;
  border: none;
  float: right;
  padding: 0 20px 0 20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.main-panel-header-btn > a > img {
  margin-right: 10px;
}

.main-panel-header-btn > button {
  height: 40px;
  background: #ae00b9;
  border-radius: 10px;
  border: none;
  float: right;
  padding: 0 20px 0 20px;

  display: flex;
  align-items: center;
}

.main-panel-header-btn > button a {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
  text-decoration: none !important;
}

.main-panel-header-btn > button > img {
  margin-right: 10px;
}

.main-stricture-header-p > a {
  height: 40px;
  background: #ae00b9;
  border-radius: 10px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  float: right;
  padding: 0 20px 0 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.main-stricture-header-p > a > img {
  margin-right: 10px;
}

.main-panel-body {
  width: 100%;
  min-height: 500px;
  padding: 20px;
}

.main-panel-body-table {
  width: 100%;
}

.main-panel-body-table thead {
  height: 50px;
  padding: 20px !important;
  background: #f3f3f5;
  border-radius: 12px !important;
}

.main-panel-body-table th {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #130f40;
  position: relative;
  padding-left: 15px;
}

.main-panel-body-table th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.main-panel-body-table th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.main-panel-body-table td {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #5a5779;
  padding: 15px;
}

.main-panel-body-table tr {
  padding: 20px;
}

.main-panel-body-table-tr:hover {
  box-shadow: inset 0 0 0 #dadce0, inset 0 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 15%), 0 1px 0.5px 1px rgb(60 64 67 / 0%);
}

.main-panel-head-table-tr {
  padding: 20px;
}

.main-panel-body-table-td > p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #c64dce;
}

.main-panel-body-table-td > img {
  cursor: pointer;
}

.accordion-click {
  cursor: pointer;
}

/*Dashboard Section Start*/
.dashboard-notification {
  height: 150px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 50px rgba(12, 25, 40, 0.05);
  border-radius: 20px;
  padding: 23px 19px 24px 19px;
}

.dashboard-notification > h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #1c2649;
  margin-bottom: 21px;
}

.dashboard-notification > h1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: rgba(28, 38, 73, 0.6);
}

.dashboard-notification-latest {
  background: #ffffff;
  box-shadow: 0 0 50px rgba(12, 25, 40, 0.05);
  border-radius: 20px;
  width: 100%;
  padding: 23px 19px 20px 19px;
  margin-bottom: 30px;
}

.dashboard-notification-latest-header {
  height: 48px;
  width: 100%;
  border-bottom: 1px solid #bababd;
  margin-bottom: 20px;
}

.dashboard-notification-latest-header > h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #1c2649;
}

.dashboard-notification-latest-header > h3 > select {
  width: 135px;
  float: right;
  background: rgba(28, 38, 73, 0.05);
  border-radius: 6px;
  border: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1c2649;
  padding: 7px 0 7px 11px;
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + -1px),
    calc(100% - 15px) calc(1em + -1px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
}

.dashboard-notification-latest-header > h3 > select:focus-visible {
  outline: none;
}

.latest-header-month-select {
  width: 135px !important;
  background: url("../images/Calendar.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  -webkit-appearance: none !important;
  height: 30px !important;
  background-color: rgba(28, 38, 73, 0.05) !important;
  border-radius: 6px !important;
  padding-right: 7.5px !important;
}

.dashboard-notification-empty-item {
  height: auto;
  width: 100%;
  mix-blend-mode: normal;
  text-align: center;
}

.dashboard-notification-empty-item > button {
  width: 143px;
  height: 45px;
  background: rgba(174, 0, 185, 0.05);
  border-radius: 8px;
  border: none;
  margin: auto;
}

.dashboard-notification-empty-item > button > a {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ae00b9;
  text-decoration: none;
}

.dashboard-notification-empty-item > p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(28, 38, 73, 0.7);
}

.dashboard-notification-empty-item > img {
  height: 100%;
  width: 100%;
}

.dashboard-notification-latest-item {
  height: 130px;
  width: 100%;
  background: rgba(0, 150, 134, 0.05);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;
}

.dashboard-notification-latest-item-img {
  width: 30%;
  height: 100%;
}

.dashboard-notification-latest-item-img > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.dashboard-notification-latest-item-detail {
  width: 68%;
  height: 100%;
  margin-left: 2%;
}

.dashboard-notification-latest-item-detail > h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #1c2649;
  padding: 5px 10px 0 0;
  width: 100%;
  max-height: 40px;
}

.dashboard-notification-latest-item-detail > h3 > b {
  width: 50% !important;
  overflow: hidden !important;
}

.dashboard-notification-latest-item-detail > h3 > span {
  text-align: right;
  float: right;
  margin-left: 4px;
}

.dashboard-notification-latest-item-detail > h3 > span > button {
  width: 72px;
  height: 17px;
  background: #25d4a9;
  border-radius: 3px;
  border: none;
  color: white;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
}

.dashboard-notification-latest-item-detail > h3 > span > img {
  margin-left: 15px;
}

.dashboard-notification-latest-item-detail > p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(28, 38, 73, 0.7);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 5px;
}

.dashboard-notification-latest-item-detail > h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(19, 15, 64, 0.5);
  padding: 5px 10px 0 0;
}

.dashboard-notification-latest-item-detail > h4 > span {
  text-align: right;
  float: right;
}

.dashboard-notification-latest-item-detail-bar {
  height: 6px;
  width: 100%;
  background: rgba(28, 38, 73, 0.1);
  border-radius: 3px;
  margin: 0px 10px 0 0;
  display: flex;
  cursor: pointer;
}

.bar-complete {
  height: 6px;
  background: #007686;
  border-radius: 3px;
}

.bar-complete_bar {
  width: 2px;
  height: 8px;
  background: #007686;
  border-radius: 2px;
  position: relative;
  top: -1px;
  right: 5%;
  float: right;
  display: none;
}

.bar-complete-text {
  width: 46px;
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
  top: -28px;
  left: -34px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #130f40;
  text-align: center;
  padding-top: 4px;
  display: none;
}

.bar-complete-text-arow {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

/*Campaign Section Start*/
.campaign-panel {
  background: none;
  box-shadow: none;
  height: auto;
}

.campaign-create {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(12, 25, 40, 0.05);
  border-radius: 20px;
  padding-bottom: 23px;
}

.campaign-create-prev {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(12, 25, 40, 0.05);
  border-radius: 20px;
}

.campaign-create-header {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #1c2649;
  border-bottom: 1px solid #d2d4db;
  height: 70px;
  padding: 23px 0 0 19px;
}

.campaign-create-input {
  padding: 23px 19px 0 19px;
}

.campaign-create-input > label {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1c2649;
  opacity: 0.7;
  margin-bottom: 11px;
}

.campaign-create-input > input {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d4db;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}

.campaign-create-input > input,
textarea:focus-visible {
  outline: none;
}

.campaign-create-input-custom {
  width: 100%;
  height: 45px;
  border: 1px solid #d0cfd9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 4px;
}

.campaign-create-input-custom > input {
  border: none;
  background: none;
  width: 94%;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
}

.campaign-create-input-custom > input:focus-visible {
  background-color: none;
}

.campaign-create-input > textarea {
  width: 100%;
  height: 250px;
  border: 1px solid #d2d4db;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}

.campaign-create-btn {
  margin: 23px 0 0 19px;
}

.campaign-create-btn-pub {
  border: 1px solid #009686;
  color: #009686;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
  margin-right: 15px;
}

.campaign-create-btn-pre {
  border: 1px solid #bc23bf;
  color: #bc23bf;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
  margin-right: 15px;
}

.campaign-create-btn-drft {
  border: 1px solid #0d6efd;
  color: #0d6efd;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
}

.campaign-create-btn-close {
  border: 1px solid red;
  color: red;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
}

.campaign-create-btn-close:hover {
  border: none;
  color: white;
  background: red;
}

.campaign-create-btn-pre:hover {
  border: none;
  color: white;
  background: #bc23bf;
}

.campaign-create-btn-pub:hover {
  border: none;
  color: white;
  background: #009686;
}

.campaign-create-btn-drft:hover {
  border: none;
  color: white;
  background: #0d6efd;
}

.campaign-create-prev-header {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #1c2649;
  height: 70px;
  padding: 23px 0 0 19px;
}

.campaign-create-prev-img {
  padding: 0 19px 50px 19px;
  width: 100%;
}

.campaign-create-prev-img > img {
  width: 100%;
  height: 100%;
}

.campaign-create-prev-title {
  width: 100%;
  padding: 0 19px 10px 19px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
}

.campaign-create-prev-title > p {
  width: 100%;
  height: 20px;
  background: #c4c4c4;
  border-radius: 4px;
}

.campaign-create-prev-detail {
  padding: 0 19px 23px 19px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  opacity: 0.7;
}

.campaign-create-prev-detail > p {
  width: 100%;
  height: 10px;
  background: #c4c4c4;
  border-radius: 4px;
}

.campaign-prev-detail {
  height: 101px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.campaign-performance {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgb(12 25 40 / 5%);
  border-radius: 20px;
}

.campaign-performance-info {
  padding: 0 19px 10px 19px;
}

.campaign-performance-item {
  background-color: #f0f3f6;
  padding: 15px 15px 2px 15px;
  border-radius: 10px;
}

.campaign-performance-item > h4 {
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(28, 38, 73, 0.6);
}

.campaign-performance-item > h1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #1c2649;
}

.campaign-performance-item > p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(28, 38, 73, 0.6);
}

.campaign-performance-item > p > b {
  float: right;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #1c2649;
}

.campaign-performance-activity {
  padding: 0 19px 10px 19px;
}

.campaign-performance-chart-item {
}

.campaign-performance-chart-item > h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(28, 38, 73, 0.6);
}

.campaign-performance-chart-item-data {
  display: flex;
}

.campaign-performance-chart-item-data > p {
  background: #009686;
  border-radius: 5px;
  width: 90%;
  height: 20px;
  max-width: 90%;
}

.campaign-performance-chart-item-data > b {
  color: #009686;
  left: 5px;
  position: relative;
  bottom: 3px;
}

.pl_0 {
  padding-left: 0 !important;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.software-button {
  width: 65px;
  height: 26px;
  background: #ecfcf3;
  border-radius: 6px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.software-button > button {
  border: none;
  background: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #17dc69;
}

.notification-button {
  width: 145px;
  height: 26px;
  background: #fef2f2;
  border-radius: 6px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.notification-button > button {
  border: none;
  background: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f50303;
}

.web-button {
  width: 85px;
  height: 26px;
  border-radius: 6px;
  margin-right: 5px;
  margin-bottom: 5px;
  background: #fbf2fb;
}

.web-button > button {
  border: none;
  background: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ae00b9;
}

.edit-button {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #e9fbf6;
  margin-right: 5px;
}

.edit-button > button {
  border: none;
  background: none;
  padding-left: 9px;
  padding-top: 5px;
}

.link-button {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #f7e5f8;
  margin-right: 5px;
}

.link-button > button {
  border: none;
  background: none;
  padding-left: 10px;
  padding-top: 7px;
}

.more-button {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #ffe5e6;
}

.more-button > button {
  border: none;
  background: none;
  padding-left: 6px;
  padding-top: 7px;
}

.add-user-submit-button {
  width: 137px;
  height: 45px;
  border: none;
  background: #ae00b9;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  float: right;
}

.modal-content-custom {
  background: #ffffff !important;
  box-shadow: 0px 0px 50px rgba(12, 25, 40, 0.05) !important;
  border-radius: 20px !important;
  overflow: hidden;
  border: none !important;
}

.main-panel-status {
  width: 142px;
  height: 40px;
  border: 1px solid #d0cfd9;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px;
  display: flex;
}

.main-panel-status:hover {
  background-color: #fff !important;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;
  border-color: rgba(223, 225, 229, 0) !important;
}

.main-panel-status > select {
  border: none;
  background: none;
  width: 90%;
  outline: none;
  padding-left: 10px;
}

.main-panel-status > select > option {
  border: none;
  background: none;
  width: 90%;
  outline: none;
  padding-left: 10px;
  border-top-right-radius: unset;
}

.domain-list-item {
  width: 100%;
  height: 300px;
  border: 1px solid #bababd;
  border-radius: 12px;
  text-align: center;
  padding: 20px;
}

.domain-list-item:hover {
  background-color: #fff !important;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;
  border-color: rgba(223, 225, 229, 0) !important;
}

.domain-list-item-icon {
  height: 50px;
  margin-bottom: 20px;
}

.domain-list-item-icon > img {
  width: 50px;
  height: 100%;
}

.domain-list-item-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.domain-list-item-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.domain-list-item-info-item {
  width: 30%;
  border: 1px solid #bababd;
  border-radius: 12px;
  margin-right: 3.33%;
  padding: 10px 10px 0px 10px;
}

.domain-list-item-info-item > h5 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.domain-list-item-info-item > h6 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.domain-list-item-btn > button {
  background: none;
  border: 1px solid #bababd;
  border-radius: 6px;
  padding: 5px 10px 5px 10px;
}

.edit-user-item-dis {
  width: 31px;
  height: 31px;
  background-color: #d3e4e0;
  border-radius: 7px;
  background-image: url("../images/editicon.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.edit-user-item-dis:hover {
  background-color: #25d4a9;
  background-image: url("../images/editIconColor.svg");
}

.delete-user-item-dis {
  width: 31px;
  height: 31px;
  background-color: #e8cfcf;
  border-radius: 7px;
  background-image: url("../images/Delete.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 10px;
}
.delete-user-item-dis:hover {
  background-color: #fe0002;
  background-image: url("../images/DeleteWhite.svg");
}

.hide {
  display: none;
}

.un-hide {
  display: block;
}

/*MODAL FADE LEFT BOTTOM */

.modal.left {
  font-family: Jost, sans-serif;
  z-index: 99999;
}

.modal.left .modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  min-width: 300px;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-dialog.modal-sm {
  max-width: 300px;
  height: 100vh;
  overflow-y: auto;
}

.modal.left .modal-content {
  min-height: 100vh;
  border: 0;
  height: 100vh;
  display: block;
  border-radius: 0;
}

.modal.left .modal-header {
  height: 58px;
}

.modal.left .modal-body {
  height: calc(100vh - 58px);
  overflow: auto;
}

.mobile-menu {
  list-style: none !important;
  padding-left: 5px;
}

.mobile-menu-sub {
  list-style: none !important;
  padding-left: 10px;
}

.mobile-menu li {
  font-size: 20px;
  line-height: 39px;
  list-style: none !important;
}

.mobile-menu-sub li {
  font-size: 20px;
  list-style: none !important;
  padding-left: 1px;
  line-height: 20px;
}

.mobile-menu a {
  padding: 6px 8px 6px 0px;
  text-decoration: none;
  font-size: 20px;
  font-family: Lexend, sans-serif;
  color: #818181;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  display: flex;
}

.mobile-menu-sub a {
  text-decoration: none;
  font-size: 20px;
  font-family: Lexend, sans-serif;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.mobile-menu-sub-button {
  border: none;
  width: 150px;
  height: 45px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  background: #00a69c;
  border-radius: 22.5px;
  outline: none;
  margin-top: 50px;
}

.close {
  border: none !important;
  background: none !important;
  color: red !important;
}
.modal-input {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.suggestions-list {
  padding-left: 0;
}
.suggestions-list li {
  list-style: none;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px 5px;
  border-bottom: 1px solid #d0cfd9;
}
.suggestions-list li:last-child {
  border-bottom: unset;
}
.suggestions-list li:hover {
  background-color: rgba(198, 110, 204, 0.09411764705882353);
}
.modal-input > div {
  width: 100%;
}
.modal-input input {
  height: 50px;
  background-color: rgba(198, 110, 204, 0.09411764705882353);
  border: unset;
  border-radius: 5px;
  border-bottom: 2px solid #ae00b9;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  padding-left: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.modal-input input:focus-visible {
  outline: unset;
}
.modal-input-show {
  display: flex;
  align-items: center;
}
.modal-input-show-text {
  flex: 1 1;
  font-size: 12px;
  padding-left: 10px;
}
.modal-input-show button {
  border: unset;
  background: unset;
}
.modal-footer > button {
  background-color: unset;
  border: unset;
  padding: 5px;
  font-size: 15px;
}
.contract-create {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgb(12 25 40 / 5%);
  border-radius: 20px;
  padding-bottom: 23px;
  display: flex;
  flex-wrap: wrap;
}
.contract-create-header {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #1c2649;
  border-bottom: 1px solid #d2d4db;
  height: 70px;
  padding: 23px 0 0 19px;
  width: 100%;
}
.contract-create-btn-add {
  border: 1px solid #ae00b9;
  color: #ae00b9;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
  margin-right: 15px;
}
.contract-create-btn-add:hover {
  border: unset;
  color: white;
  background: #ae00b9;
}
.contract-create-input {
  padding: 23px 19px 0 19px;
  width: 50%;
}
.contract-create-input2 {
  padding: 23px 19px 0 19px;
  width: 25%;
}
.contract-create-input > label {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1c2649;
  opacity: 0.7;
  margin-bottom: 11px;
}
.contract-create-input2 > label {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1c2649;
  opacity: 0.7;
  margin-bottom: 11px;
}
.contract-create-input-custom {
  width: 100%;
  height: 45px;
  border: 1px solid #d0cfd9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 4px;
  position: relative;
}
.contract-create-input-custom > .modal-input {
  width: 100%;
}
.contract-create-input-custom input {
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  display: flex !important;
  padding: 4px !important;
  border-bottom: unset;
  background-color: unset;
  margin-bottom: unset;
}
.contract-create-input > input {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d4db;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}
.contract-create-input2 > input {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d4db;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}
.contract-create-input > select {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d4db;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}
.contract-create-input-custom > .suggestions-list {
  position: absolute;
  left: -1px;
  z-index: 999;
  background: white;
  top: 45px;
  width: 101%;
  border: 1px solid #d0cfd9;
  border-radius: 10px;
}
.contract-create-btn {
  width: 100%;
  margin: 23px 0 0 19px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contract-cancel-btn {
  border: 1px solid red;
  color: red;
  background: none;
  border-radius: 10px;
  height: 45px;
  padding: 0 20px 0 20px;
  margin-right: 15px;
}

.contract-cancel-btn:hover {
  border: unset;
  color: white;
  background: red;
}
.action-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-area > button {
  border: unset;
  background: unset;
}
